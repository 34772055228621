import React from "react"
import { Link, graphql } from "gatsby"

import {
    MdArrowBack,

} from 'react-icons/md';


import SEO from "../components/seo"
import Layout from "../components/layout"

const Nosotros = ({ data: { image1, vision, mision, valores, historia } }) => {



    return (
        <Layout changeHeader={2}>
            <SEO title={`Nosotros`} />

            <section className="hero-wrap hero-wrap-2 mb-2 top_btn" style={{ backgroundImage: `url(${image1.childImageSharp.fluid.src})`, height: '450px' }} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-1" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                            <Link to="/#" className="icon ml-5 btn btn-primary" title="Regresar">
                                <MdArrowBack
                                    color="#fff"
                                    size="2.3em"
                                />
                            </Link>

                        </div>
                        <div className="col-md-11 text-center">
                            <h1 className="mb-2 bread" data-sal="slide-left" data-sal-duration="2000" data-sal-easing="ease" id="title_nosotros">Nosotros </h1>
                            <p className="breadcrumbs"><span className="mr-2">

                                <Link className="" to="/#">Inicio</Link> > </span> <span>Nosotros</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-counter img ftco-section ftco-no-pt ftco-no-pb" id="about">
                <div className="container">
                    <div className="row d-flex">

                        <div className="col-md-6 col-lg-5 d-flex " data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease">
                            <div className="img d-flex align-self-stretch align-items-center" >
                                <img className="img-fluid img icon_nosotros" src={historia.childImageSharp.fluid.src} alt="Icon de visión"/>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7 ">
                            <div className="py-4">
                                <div className="row justify-content-start pb-3">
                                    <div className="col-md-12 heading-section " data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                                        <h2 className="mb-4" style={{ fontSize: '34px', textTransform: 'capitalize' }}>Historia</h2>
                                        <p className="text-justify">
                                        Distribuidora Yonlui, c.a. fue fundada en Noviembre del 2008 e inicia actividades formales en Abril del 2009. Su cobertura es el Estado Portuguesa con sede en la ciudad de Acarigua. Esta iniciativa nace con la finalidad de lograr alianzas comerciales con empresas productoras líderes, aprovechando todo el «Know How» en el área comercial con el que cuenta el equipo de Distribuidora Yonlui, c.a. y los cambios en los modelos de Distribución y Ventas que se vienen presentando en los últimos tiempos. Inicialmente enfoca sus distribuciones en la categoría Macro snacks (pasapalos, galletas, chocolates y caramelos). En el 2011, con el objetivo de mejorar el servicio en los territorios de Ospino, Guanare, Guanarito, Boconoito, Biscucuy, Chabasquen y Papelón, se abre la sucursal de Guanare.
                                        A partir del 2015 y debido a las necesidades del mercado, se incorporan las categorías de alimentos, cuidado personal y limpieza que les han permitido consolidarse como la empresa distribuidora más completa del estado Portuguesa.
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <hr className="bg-primary"/>
                        </div>

                        <div className="col-md-6 col-lg-5 d-flex " data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease">
                            <div className="img d-flex align-self-stretch align-items-center" >
                                <img className="img-fluid img icon_nosotros" src={mision.childImageSharp.fluid.src} alt="Icon de visión"/>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7 ">
                            <div className="py-4">
                                <div className="row justify-content-start pb-3">
                                    <div className="col-md-12 heading-section " data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                                        <h2 className="mb-4" style={{ fontSize: '34px', textTransform: 'capitalize' }}>Misión</h2>
                                        <p className="text-justify">
                                            Garantizar el abastecimiento de productos alimenticios al comercio minorista, integrando al suministro la calidad, cantidad, precio y tiempo de entrega que cada uno requiere. Para ello, contamos con el mejor equipo humano, logístico y tecnológico que nos permite brindar la mejor experiencia de compras.
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <hr className="bg-primary"/>
                        </div>

                        <div className="col-md-6 col-lg-5 d-flex " data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease">
                            <div className="img d-flex align-self-stretch align-items-center" >
                                <img className="img-fluid img icon_nosotros" src={vision.childImageSharp.fluid.src} alt="Icon de visión"/>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7 ">
                            <div className="py-4">
                                <div className="row justify-content-start pb-3">
                                    <div className="col-md-12 heading-section " data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                                        <h2 className="mb-4" style={{ fontSize: '34px', textTransform: 'capitalize' }}>Visión</h2>
                                        <p className="text-justify">
                                            Convertirnos en el principal operador de Distribución y logística para la zona de los Llanos Occidentales.
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <hr className="bg-primary"/>
                        </div>

                        <div className="col-md-6 col-lg-5 d-flex " data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease">
                            <div className="img d-flex align-self-stretch align-items-center" >
                                <img className="img-fluid img icon_nosotros" src={valores.childImageSharp.fluid.src} alt="Icon de visión"/>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7 ">
                            <div className="py-4">
                                <div className="row justify-content-start pb-3">
                                    <div className="col-md-12 heading-section " data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                                        <h2 className="mb-4" style={{ fontSize: '34px', textTransform: 'capitalize' }}>Valores</h2>
                                        <p className="text-justify">
                                            <b>Respeto</b> Se trata de no discriminar ni deshonrar a la(s) persona(s) por su forma de vida y sus decisiones, siempre y cuando dichas decisiones no causen ningún daño, ni afecten o irrespeten a los demás.
                                        </p>
                                        <p className="text-justify">
                                            <b>Honestidad</b> Somos coherentes y sinceros de acuerdo con los valores de verdad, justicia e integridad.
                                        </p>
                                        <p className="text-justify">
                                            <b>Responsabilidad</b> Somos responsables de todas las decisiones que tomamos y de las consecuencias que tengan las citadas decisiones ante quien corresponda.
                                        </p>

                                        <p className="text-justify">
                                            Creemos en el <b>TRABAJO EN EQUIPO</b> y el <b>COMPAÑERISMO</b> como herramientas fundamentales para el logro de cualquier objetivo de forma eficiente.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


        </Layout>
    )
}

export default Nosotros

export const pageQuery = graphql`
  query QueryNosotros {

    image1: file(relativePath: { eq: "banner_nostros.png" }) {
        childImageSharp {
            fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    mision: file(relativePath: { eq: "mision.png" }) {
        childImageSharp {
            fluid(maxWidth: 510) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    vision: file(relativePath: { eq: "vision.png" }) {
        childImageSharp {
            fluid(maxWidth: 510) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    historia: file(relativePath: { eq: "historia.png" }) {
        childImageSharp {
            fluid(maxWidth: 510) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    valores: file(relativePath: { eq: "valores.png" }) {
        childImageSharp {
            fluid(maxWidth: 510) {
                ...GatsbyImageSharpFluid
            }
        }
    }


  }
`
